<template>
  <v-layout fill-height align-center justify-center>
    <section>
      <h2 class="mt-10 text-center mb-10">Sign in</h2>
      <v-form
        ref="login-form"
        class="login-form"
        lazy-validation
        @submit.prevent
      >
        <div class="mb-2">Hashtag name | Username</div>
        <v-text-field
          class="mb-6"
          placeholder="username"
          v-model="userName"
          hide-details
          :readonly="isLoading"
          rounded
          height="60"
          solo
          light
          @keypress.enter="submit()"
        ></v-text-field>

        <div class="mb-2">Mnemonic | Password</div>

        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Seed words (Mnemonic)"
          @click:append="showPassword = !showPassword"
          hide-details
          rounded
          :readonly="isLoading"
          class="mb-6"
          height="60"
          solo
          light
          @keypress.enter="submit()"
        ></v-text-field>

        <v-btn
          @click="submit()"
          :loading="isLoading"
          class="main-btn my-3"
          :class="$store.getters.theme === 'dark' ? 'white--text' : ''"
          width="100%"
          rounded
          x-large
        >
          <b>Sign in</b>
        </v-btn>
      </v-form>
      <div class="form-tag text-center">
        <strong>
          New user?
          <router-link to="/auth/signup" class="main-color">
            Sign up
          </router-link>
        </strong>
      </div>
    </section>
  </v-layout>
</template>

<script>
import { getExtPublicKey } from "@/plugins/ledgermail-jslib";
import { login } from "@/plugins/axios";
import { getLocalCookie } from "../../plugins/api/hashtag-api";

export default {
  data: () => ({
    userName: "",
    password: "",
    showPassword: false,

    isLoading: false,
  }),
  created() {
    const cookieData = JSON.parse(getLocalCookie("_mh_usr") || "{}");

    if (cookieData && cookieData.nickName)
      this.userName =
        cookieData.nickName[0] == "#"
          ? cookieData.nickName
          : "#" + cookieData.nickName;
    else
      this.$toast.error(
        "Please Signin to Hashtag.space before accessing D-Mail"
      );
  },
  methods: {
    submit() {
      const cookieData = JSON.parse(getLocalCookie("_mh_usr"));

      if (!cookieData || !cookieData.email)
        return this.$toast(
          "Please login to Hashtag.space before accessing D-Mail"
        );

      if (
        cookieData.nickName.toLowerCase() !=
        this.userName.slice(1, this.userName.length).toLowerCase()
      ) {
        this.$toast.error(
          "Provided Hashtag name is mismatching with logged in user from Hashtag.space."
        );
        setTimeout(() => {
          this.$toast(
            "Please use the same Hashtag Name used in your Hashtag account"
          );
        }, 1000);
        return;
      }

      if (this.userName[0] != "#")
        return this.$toast.error(
          `Invalid Hashtag name "${this.userName}". (try using '#' before the name)`
        );

      if (!/^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(this.password))
        return this.$toast.error(`Invalid password`);

      this.isLoading = true;

      setTimeout(async () => {
        const publicKey = getExtPublicKey(this.password);

        try {
          const signInResponse = (await login(publicKey, this.userName)).data;
          if (signInResponse.error)
            throw new Error(
              signInResponse.message
                ? signInResponse.message
                : "#404, Server not found."
            );

          await this.$store.dispatch("LOGIN_HANDLER", {
            signInResponse,
            mnemonic: this.password,
          });

          this.$router.push("/");
        } catch (error) {
          console.error(error);
          if (error.message) this.$toast.error(error.message);
          else this.$toast.error("Unexpected error! Please try after sometime");
        }

        this.isLoading = false;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 450px;
}

.form-tag {
  font-size: 20px;

  span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
